import { mapState } from 'vuex';
import DuplicateFoundDialog from '@/components/crm/addresses/addAddress/DuplicateFoundDialog.vue';
import WarningDialog from '@/components/core/WarningDialog.vue';
import axios from 'axios';
import locationService from '@/services/util/locationService.js';
export default {
  components: {
    DuplicateFoundDialog,
    WarningDialog
  },
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newNonPerson,
      options: state => state.crmAddresses.addressOptions,
      duplicateNonPersons: state => state.crmAddresses.duplicates.nonPersons,
      addressTypes: state => state.crmAddresses.addressTypes
    })
  },
  data: () => ({
    rules: {
      onlyPositive: value => value >= 0 || value == null || value == '' || 'Wert kann nicht negativ sein!',
      emailFormat: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!',
      phoneNumber: v => !v || /^(?=.{8,})\+?\d[\d\s]*$/.test(v) || 'Ungültige Telefonnummer'
    },
    formValidity: false,
    streetSuggestions: []
  }),
  methods: {
    emitChange() {
      this.$emit('change');
    },
    updateValidity(newValidity) {
      this.$store.commit('updateValidGeneralInfo', newValidity);
    },
    checkIfDuplicate() {
      //TODO: check for duplicate
      if (this.duplicateNonPersons == null && this.address.name != '' && this.address.address.zip != '' && this.address.address.city != '' && this.address.address.street != '' && this.address.address.country != null) {
        let duplicateCheckObject = {
          salutation: this.address.salutation,
          name: this.address.name,
          address: {
            zip: this.address.address.zip,
            city: this.address.address.city,
            street: this.address.address.street,
            country: this.address.address.country
          }
        };
        console.log(duplicateCheckObject);
        this.$store.dispatch('checkDuplicateNonPersons', duplicateCheckObject).then(() => {
          if (this.duplicateNonPersons != []) {
            this.$refs.warning.open('Folgende Adresse existiert bereits: \n' + `${this.duplicateNonPersons[0].displayName}.`, [{
              action: 'compare',
              text: 'Vergleichen und Fortfahren'
            }]);
          }
        });
      }
    },
    loadCompanyDefaultOptions() {
      if (this.address.isCompany) {
        this.$store.dispatch('loadCompanyOptions');
      }
    },
    loadSupplierDefaultOptions() {
      if (this.address.isSupplier) {
        this.$store.dispatch('loadSupplierOptions');
      }
    },
    loadHealthInsuranceCompanyOptions() {
      if (this.address.isHealthInsuranceCompany) {
        this.$store.dispatch('loadHealthInsuranceCompanyOptions');
      }
    },
    warningActionPerformed() {
      let comparedAddress = {
        name: this.address.name,
        zip: this.address.address.zip,
        city: this.address.address.city,
        street: this.address.address.street
      };
      this.$refs.duplicate.open(comparedAddress, this.duplicateNonPersons[0]);
    },
    autofillCity() {
      if (this.address.address.country != null && (this.address.address.zip != null || this.address.address.zip != '')) {
        locationService.getCityNameByPostcodeAndCountry(this.address.address.zip, this.address.address.country).then(res => {
          this.address.address.city = res.data;
        });
      }
    },
    updateNonPersonNavList() {
      this.$store.commit('updateNonPersonNavList');
    },
    updateStreetSuggestions() {
      axios.post('v1/crm/addresses/new/street', {
        plz: this.address.address.zip,
        query: this.address.address.street
      }).then(res => {
        this.streetSuggestions = res.data.streets;
      });
    }
  }
};