import { mapState } from 'vuex';
import GeneralinformationForm from '@/components/crm/addresses/addAddress/addNonPerson/GeneralinformationForm.vue';
import CompanyinformationForm from '@/components/crm/addresses/addAddress/addNonPerson/CompanyinformationForm.vue';
import SupplierinformationForm from '@/components/crm/addresses/addAddress/addNonPerson/SupplierinformationForm.vue';
export default {
  components: {
    GeneralinformationForm,
    CompanyinformationForm,
    SupplierinformationForm
  },
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newNonPerson,
      addressTypes: state => state.crmAddresses.addressTypes
    })
  },
  data: () => ({
    panelsOpenedByDefault: [0, 1, 2, 3, 4]
  }),
  methods: {
    emitChange() {
      this.$emit('change');
    }
  }
};