import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VForm, {
    on: {
      "input": function ($event) {
        _vm.updateValidity(_vm.formValidity), _vm.emitChange();
      }
    },
    model: {
      value: _vm.formValidity,
      callback: function ($$v) {
        _vm.formValidity = $$v;
      },
      expression: "formValidity"
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Vertragsnummer",
      "append-icon": "mdi-numeric"
    },
    model: {
      value: _vm.address.company.contractNumber,
      callback: function ($$v) {
        _vm.$set(_vm.address.company, "contractNumber", $$v);
      },
      expression: "address.company.contractNumber"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "lg": "4",
      "xl": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "UID",
      "append-icon": "mdi-identifier"
    },
    on: {
      "change": function ($event) {
        return _vm.syncUID();
      }
    },
    model: {
      value: _vm.address.company.uid,
      callback: function ($$v) {
        _vm.$set(_vm.address.company, "uid", $$v);
      },
      expression: "address.company.uid"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "5",
      "lg": "5",
      "xl": "5"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Kundengruppe",
      "items": this.options.customerGroups,
      "item-text": "name",
      "item-value": "key",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.company.customerGroup,
      callback: function ($$v) {
        _vm.$set(_vm.address.company, "customerGroup", $$v);
      },
      expression: "address.company.customerGroup"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Verband",
      "prepend-inner-icon": "mdi-account-group",
      "items": this.companyOptions.associations,
      "item-text": "name",
      "item-value": "key",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.company.association,
      callback: function ($$v) {
        _vm.$set(_vm.address.company, "association", $$v);
      },
      expression: "address.company.association"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "items": this.companyOptions.industrialSectors,
      "item-text": "description",
      "item-value": "id",
      "label": "Branche",
      "prepend-inner-icon": "mdi-account-hard-hat",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.company.industrialSectors,
      callback: function ($$v) {
        _vm.$set(_vm.address.company, "industrialSectors", $$v);
      },
      expression: "address.company.industrialSectors"
    }
  })], 1)], 1), _c('h2', {
    staticClass: "overline font-weight-black mt-3"
  }, [_vm._v("Lieferung und Bezahlung")]), _c(VDivider), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "items": this.companyOptions.shippingMethods,
      "item-text": "description",
      "item-value": "id",
      "prepend-inner-icon": "mdi-truck",
      "label": "Versandart",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.shippingMethod,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "shippingMethod", $$v);
      },
      expression: "address.company.deliveryAndPayment.shippingMethod"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "items": this.companyOptions.shippingConditions,
      "item-text": "description",
      "item-value": "id",
      "prepend-inner-icon": "mdi-truck-alert",
      "label": "Lieferbedingung",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.shippingCondition,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "shippingCondition", $$v);
      },
      expression: "address.company.deliveryAndPayment.shippingCondition"
    }
  })], 1)], 1), _c(VRow, {
    staticClass: "mt-5"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "items": this.companyOptions.paymentConditions,
      "item-text": "description",
      "item-value": "id",
      "prepend-inner-icon": "mdi-cash",
      "label": "Zahlungsbedingung",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.paymentCondition,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "paymentCondition", $$v);
      },
      expression: "address.company.deliveryAndPayment.paymentCondition"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "items": this.companyOptions.salesPersons,
      "item-text": "displayName",
      "item-value": "key",
      "label": "Verkäufer",
      "prepend-inner-icon": "mdi-account",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.salesPerson,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "salesPerson", $$v);
      },
      expression: "address.company.deliveryAndPayment.salesPerson"
    }
  })], 1)], 1), _c(VRow, {
    staticClass: "mt-5"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "suffix": "Tage",
      "rules": [_vm.rules.onlyNumbers, _vm.rules.onlyPositive],
      "outlined": "",
      "label": "Sammelliefers. Int."
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.collectiveDeliveryNoteInterval,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "collectiveDeliveryNoteInterval", $$v);
      },
      expression: "\n          address.company.deliveryAndPayment.collectiveDeliveryNoteInterval\n        "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "suffix": "Tage",
      "rules": [_vm.rules.onlyNumbers, _vm.rules.onlyPositive],
      "outlined": "",
      "label": "Sammelr. Int."
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.collectiveInvoiceInterval,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "collectiveInvoiceInterval", $$v);
      },
      expression: "address.company.deliveryAndPayment.collectiveInvoiceInterval"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "xl": "6",
      "md": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Lager",
      "items": this.companyOptions.warehouses,
      "item-text": "description",
      "item-value": "id",
      "prepend-inner-icon": "mdi-warehouse",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.warehouse,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "warehouse", $$v);
      },
      expression: "address.company.deliveryAndPayment.warehouse"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "5",
      "xl": "5",
      "md": "5"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Rabattart",
      "items": this.companyOptions.discountTypes,
      "item-text": "description",
      "item-value": "id",
      "prepend-inner-icon": "mdi-file-percent",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.discountType,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "discountType", $$v);
      },
      expression: "address.company.deliveryAndPayment.discountType"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "suffix": "%",
      "label": "Rabattausmaß",
      "rules": [_vm.rules.onlyNumbers, _vm.rules.onlyPositive]
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.discount,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "discount", $$v);
      },
      expression: "address.company.deliveryAndPayment.discount"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "xl": "4",
      "md": "4"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Verband ist Rechnungsadresse"
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.associationIsInvoiceAddress,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "associationIsInvoiceAddress", $$v);
      },
      expression: "\n          address.company.deliveryAndPayment.associationIsInvoiceAddress\n        "
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "8",
      "xl": "8",
      "md": "8"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "items": this.companyOptions.fibuGroups,
      "item-text": "description",
      "item-value": "id",
      "label": "Fibugruppe",
      "prepend-inner-icon": "mdi-account-group",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.company.deliveryAndPayment.fibuGroup,
      callback: function ($$v) {
        _vm.$set(_vm.address.company.deliveryAndPayment, "fibuGroup", $$v);
      },
      expression: "address.company.deliveryAndPayment.fibuGroup"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };