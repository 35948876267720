import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VForm, {
    on: {
      "input": function ($event) {
        _vm.updateValidity(_vm.formValidity), _vm.emitChange();
      }
    },
    model: {
      value: _vm.formValidity,
      callback: function ($$v) {
        _vm.formValidity = $$v;
      },
      expression: "formValidity"
    }
  }, [_c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('h2', {
    staticClass: "overline font-weight-black"
  }, [_vm._v("Adresstypen*")]), _c(VDivider), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.addressTypes.find(type => type.fldName === 'kd_kunde').fldDisplayName
    },
    on: {
      "change": function ($event) {
        _vm.loadCompanyDefaultOptions(), _vm.updateNonPersonNavList(), _vm.emitChange();
      }
    },
    model: {
      value: _vm.address.isCompany,
      callback: function ($$v) {
        _vm.$set(_vm.address, "isCompany", $$v);
      },
      expression: "address.isCompany"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.addressTypes.find(type => type.fldName === 'kd_lieferant').fldDisplayName
    },
    on: {
      "change": function ($event) {
        _vm.loadSupplierDefaultOptions(), _vm.updateNonPersonNavList(), _vm.emitChange();
      }
    },
    model: {
      value: _vm.address.isSupplier,
      callback: function ($$v) {
        _vm.$set(_vm.address, "isSupplier", $$v);
      },
      expression: "address.isSupplier"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.addressTypes.find(type => type.fldName === 'kd_heim').fldDisplayName
    },
    on: {
      "change": function ($event) {
        _vm.updateNonPersonNavList(), _vm.emitChange();
      }
    },
    model: {
      value: _vm.address.isResidence,
      callback: function ($$v) {
        _vm.$set(_vm.address, "isResidence", $$v);
      },
      expression: "address.isResidence"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.addressTypes.find(type => type.fldName === 'kd_krankenkasse').fldDisplayName
    },
    on: {
      "change": function ($event) {
        _vm.loadHealthInsuranceCompanyOptions(), _vm.updateNonPersonNavList(), _vm.emitChange();
      }
    },
    model: {
      value: _vm.address.isHealthInsuranceCompany,
      callback: function ($$v) {
        _vm.$set(_vm.address, "isHealthInsuranceCompany", $$v);
      },
      expression: "address.isHealthInsuranceCompany"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.addressTypes.find(type => type.fldName === 'kd_sachwalter').fldDisplayName
    },
    on: {
      "change": function ($event) {
        _vm.updateNonPersonNavList(), _vm.emitChange();
      }
    },
    model: {
      value: _vm.address.isAdultRepresentation,
      callback: function ($$v) {
        _vm.$set(_vm.address, "isAdultRepresentation", $$v);
      },
      expression: "address.isAdultRepresentation"
    }
  })], 1)], 1), _c('h2', {
    staticClass: "overline font-weight-black mt-3"
  }, [_vm._v("Allgemeine Informationen")]), _c(VDivider), _c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": "4",
      "sm": "3",
      "md": "3",
      "xl": "3",
      "lg": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Anrede"
    },
    model: {
      value: _vm.address.salutation,
      callback: function ($$v) {
        _vm.$set(_vm.address, "salutation", $$v);
      },
      expression: "address.salutation"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "9",
      "md": "9",
      "lg": "9",
      "xl": "9"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* Name",
      "append-icon": "mdi-domain",
      "rules": [_vm.rules.required]
    },
    on: {
      "change": function ($event) {
        return _vm.checkIfDuplicate();
      }
    },
    model: {
      value: _vm.address.name,
      callback: function ($$v) {
        _vm.$set(_vm.address, "name", $$v);
      },
      expression: "address.name"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "4",
      "lg": "4"
    }
  }, [_c(VAutocomplete, {
    ref: "countryAutocomplete",
    staticClass: "no-rotate",
    attrs: {
      "append-icon": "mdi-earth",
      "outlined": "",
      "label": "* Land",
      "items": this.options.countries,
      "item-text": "description",
      "item-value": "id",
      "rules": [_vm.rules.required],
      "clearable": "",
      "auto-select-first": ""
    },
    on: {
      "change": function ($event) {
        _vm.checkIfDuplicate(), _vm.autofillCity();
      }
    },
    model: {
      value: _vm.address.address.country,
      callback: function ($$v) {
        _vm.$set(_vm.address.address, "country", $$v);
      },
      expression: "address.address.country"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "xl": "3",
      "lg": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* PLZ",
      "rules": [_vm.rules.onlyPositive, _vm.rules.required]
    },
    on: {
      "change": function ($event) {
        _vm.checkIfDuplicate(), _vm.autofillCity();
      }
    },
    model: {
      value: _vm.address.address.zip,
      callback: function ($$v) {
        _vm.$set(_vm.address.address, "zip", $$v);
      },
      expression: "address.address.zip"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "5",
      "xl": "5",
      "lg": "5"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* Ort",
      "rules": [_vm.rules.required]
    },
    on: {
      "change": function ($event) {
        return _vm.checkIfDuplicate();
      }
    },
    model: {
      value: _vm.address.address.city,
      callback: function ($$v) {
        _vm.$set(_vm.address.address, "city", $$v);
      },
      expression: "address.address.city"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "12",
      "xl": "12",
      "lg": "12"
    }
  }, [_c(VCombobox, {
    staticClass: "no-rotate",
    attrs: {
      "outlined": "",
      "label": "* Straße & Hausnummer",
      "append-icon": "mdi-home",
      "rules": [_vm.rules.required],
      "items": this.streetSuggestions,
      "auto-select-first": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.checkIfDuplicate();
      },
      "keyup": function ($event) {
        return _vm.updateStreetSuggestions();
      }
    },
    model: {
      value: _vm.address.address.street,
      callback: function ($$v) {
        _vm.$set(_vm.address.address, "street", $$v);
      },
      expression: "address.address.street"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "xl": "3",
      "lg": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* Tel. 1",
      "append-icon": "mdi-phone",
      "rules": [_vm.rules.phoneNumber, _vm.rules.required]
    },
    model: {
      value: _vm.address.tel,
      callback: function ($$v) {
        _vm.$set(_vm.address, "tel", $$v);
      },
      expression: "address.tel"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "xl": "3",
      "lg": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-pencil",
      "label": "Anmerkung"
    },
    model: {
      value: _vm.address.telNote,
      callback: function ($$v) {
        _vm.$set(_vm.address, "telNote", $$v);
      },
      expression: "address.telNote"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "xl": "3",
      "lg": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Tel. Mobil",
      "append-icon": "mdi-phone",
      "rules": [_vm.rules.phoneNumber]
    },
    model: {
      value: _vm.address.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.address, "mobile", $$v);
      },
      expression: "address.mobile"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "xl": "3",
      "lg": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-pencil",
      "label": "Anmerkung"
    },
    model: {
      value: _vm.address.mobileNote,
      callback: function ($$v) {
        _vm.$set(_vm.address, "mobileNote", $$v);
      },
      expression: "address.mobileNote"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-email",
      "label": "E-Mail",
      "placeholder": "max.mustermann@gmail.com",
      "rules": [_vm.rules.emailFormat],
      "type": "email"
    },
    model: {
      value: _vm.address.email,
      callback: function ($$v) {
        _vm.$set(_vm.address, "email", $$v);
      },
      expression: "address.email"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Heim",
      "clearable": "",
      "items": this.options.residences,
      "item-text": "displayName",
      "item-value": "key",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.residence,
      callback: function ($$v) {
        _vm.$set(_vm.address, "residence", $$v);
      },
      expression: "address.residence"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "12",
      "lg": "12",
      "xl": "12"
    }
  }, [_c(VAutocomplete, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.address.isAdultRepresentation,
      expression: "!address.isAdultRepresentation"
    }],
    attrs: {
      "outlined": "",
      "label": "Erwachsenenvertretung",
      "items": this.options.adultRepresentations,
      "item-text": "displayName",
      "item-value": "key",
      "clearable": "",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.adultRepresentation,
      callback: function ($$v) {
        _vm.$set(_vm.address, "adultRepresentation", $$v);
      },
      expression: "address.adultRepresentation"
    }
  })], 1), _c(VSpacer)], 1)], 1), _c('WarningDialog', {
    ref: "warning",
    on: {
      "warningActionPerformed": _vm.warningActionPerformed
    }
  }), _c('DuplicateFoundDialog', {
    ref: "duplicate"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };