import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VForm, {
    on: {
      "input": function ($event) {
        _vm.updateValidity(_vm.formValidity), _vm.emitChange();
      }
    },
    model: {
      value: _vm.formValidity,
      callback: function ($$v) {
        _vm.formValidity = $$v;
      },
      expression: "formValidity"
    }
  }, [_c(VContainer, {
    attrs: {
      "fluid": ""
    }
  }, [_c('h2', {
    staticClass: "overline font-weight-black"
  }, [_vm._v("* Adresstypen")]), _c(VDivider), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.addressTypes.find(type => type.fldName === 'kd_konsument').fldDisplayName
    },
    on: {
      "change": function ($event) {
        _vm.updatePersonNavList(), _vm.emitChange(), _vm.updateValidity(_vm.formValidity);
      }
    },
    model: {
      value: _vm.address.isConsumer,
      callback: function ($$v) {
        _vm.$set(_vm.address, "isConsumer", $$v);
      },
      expression: "address.isConsumer"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.addressTypes.find(type => type.fldName === 'kd_sachwalter').fldDisplayName
    },
    on: {
      "change": function ($event) {
        _vm.updatePersonNavList(), _vm.emitChange(), _vm.updateValidity(_vm.formValidity);
      }
    },
    model: {
      value: _vm.address.isAdultRepresentation,
      callback: function ($$v) {
        _vm.$set(_vm.address, "isAdultRepresentation", $$v);
      },
      expression: "address.isAdultRepresentation"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.addressTypes.find(type => type.fldName === 'kd_patient').fldDisplayName
    },
    on: {
      "change": function ($event) {
        _vm.loadPatientDefaults(), _vm.updatePersonNavList(), _vm.emitChange(), _vm.updateValidity(_vm.formValidity);
      }
    },
    model: {
      value: _vm.address.isPatient,
      callback: function ($$v) {
        _vm.$set(_vm.address, "isPatient", $$v);
      },
      expression: "address.isPatient"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.addressTypes.find(type => type.fldName === 'kd_arzt').fldDisplayName
    },
    on: {
      "change": function ($event) {
        _vm.loadDoctorDefaults(), _vm.updatePersonNavList(), _vm.emitChange(), _vm.updateValidity(_vm.formValidity);
      }
    },
    model: {
      value: _vm.address.isDoctor,
      callback: function ($$v) {
        _vm.$set(_vm.address, "isDoctor", $$v);
      },
      expression: "address.isDoctor"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.addressTypes.find(type => type.fldName === 'kd_verkaeufer').fldDisplayName
    },
    on: {
      "change": function ($event) {
        _vm.loadEmployeeDefaults(), _vm.updatePersonNavList(), _vm.emitChange();
      }
    },
    model: {
      value: _vm.address.isEmployee,
      callback: function ($$v) {
        _vm.$set(_vm.address, "isEmployee", $$v);
      },
      expression: "address.isEmployee"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "lg": "3",
      "xl": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": _vm.addressTypes.find(type => type.fldName === 'kd_interessent').fldDisplayName
    },
    on: {
      "change": function ($event) {
        _vm.updatePersonNavList(), _vm.emitChange();
      }
    },
    model: {
      value: _vm.address.isInterested,
      callback: function ($$v) {
        _vm.$set(_vm.address, "isInterested", $$v);
      },
      expression: "address.isInterested"
    }
  })], 1)], 1), _c('h2', {
    staticClass: "overline font-weight-black mt-3"
  }, [_vm._v("Adressinformationen")]), _c(VDivider), _c(VRow, {
    staticClass: "mt-2"
  }, [_c(VCol, {
    attrs: {
      "cols": "4",
      "md": "2",
      "xl": "2",
      "lg": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* Anrede",
      "rules": [_vm.rules.required]
    },
    model: {
      value: _vm.address.salutation,
      callback: function ($$v) {
        _vm.$set(_vm.address, "salutation", $$v);
      },
      expression: "address.salutation"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "8",
      "md": "2",
      "xl": "2",
      "lg": "2"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Titel",
      "search-input": _vm.title,
      "items": _vm.titleSuggestions,
      "auto-select-first": "",
      "no-data-text": "Geben Sie einen Titel ein"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.title = $event;
      },
      "update:search-input": function ($event) {
        _vm.title = $event;
      },
      "keyup": function ($event) {
        return _vm.autofillTitle();
      }
    },
    model: {
      value: _vm.address.title,
      callback: function ($$v) {
        _vm.$set(_vm.address, "title", $$v);
      },
      expression: "address.title"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "4",
      "lg": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-account",
      "label": "* Nachname",
      "rules": [_vm.rules.required]
    },
    on: {
      "change": function ($event) {
        return _vm.checkIfDuplicate();
      }
    },
    model: {
      value: _vm.address.lastname,
      callback: function ($$v) {
        _vm.$set(_vm.address, "lastname", $$v);
      },
      expression: "address.lastname"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "4",
      "lg": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-account",
      "label": "* Vorname",
      "rules": [_vm.rules.required]
    },
    on: {
      "change": function ($event) {
        _vm.checkIfDuplicate(), _vm.autofillGender();
      }
    },
    model: {
      value: _vm.address.firstname,
      callback: function ($$v) {
        _vm.$set(_vm.address, "firstname", $$v);
      },
      expression: "address.firstname"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "4",
      "lg": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "* Geschlecht",
      "items": _vm.options.genders,
      "item-text": "bezeichnung",
      "item-value": "key",
      "clearable": "",
      "rules": [_vm.rules.required],
      "auto-select-first": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateSalutation();
      }
    },
    model: {
      value: _vm.address.gender,
      callback: function ($$v) {
        _vm.$set(_vm.address, "gender", $$v);
      },
      expression: "address.gender"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "4",
      "lg": "4"
    }
  }, [_c('LBADatepickerInput', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.legalDate],
      "label": '* Geburtsdatum'
    },
    on: {
      "blur": function ($event) {
        _vm.date = _vm.parseDate(_vm.dateFormatted);
      },
      "datePicked": function ($event) {
        return _vm.checkIfDuplicate();
      }
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "4",
      "lg": "4"
    }
  }, [_c(VAutocomplete, {
    staticClass: "no-rotate",
    attrs: {
      "append-icon": "mdi-earth",
      "outlined": "",
      "label": "* Land",
      "rules": [_vm.rules.required],
      "items": this.options.countries,
      "item-text": "description",
      "item-value": "id",
      "clearable": "",
      "auto-select-first": ""
    },
    on: {
      "change": function ($event) {
        _vm.checkIfDuplicate(), _vm.autofillCity();
      }
    },
    model: {
      value: _vm.address.address.country,
      callback: function ($$v) {
        _vm.$set(_vm.address.address, "country", $$v);
      },
      expression: "address.address.country"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "2",
      "xl": "2",
      "lg": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* PLZ",
      "rules": [_vm.rules.onlyPositive, _vm.rules.required]
    },
    on: {
      "change": function ($event) {
        _vm.checkIfDuplicate(), _vm.autofillCity();
      }
    },
    model: {
      value: _vm.address.address.zip,
      callback: function ($$v) {
        _vm.$set(_vm.address.address, "zip", $$v);
      },
      expression: "address.address.zip"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "5",
      "xl": "5",
      "lg": "5"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* Ort",
      "rules": [_vm.rules.required]
    },
    on: {
      "change": function ($event) {
        return _vm.checkIfDuplicate();
      }
    },
    model: {
      value: _vm.address.address.city,
      callback: function ($$v) {
        _vm.$set(_vm.address.address, "city", $$v);
      },
      expression: "address.address.city"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "5",
      "xl": "5",
      "lg": "5"
    }
  }, [_c(VCombobox, {
    staticClass: "no-rotate",
    attrs: {
      "outlined": "",
      "label": "* Straße & Hausnummer",
      "append-icon": "mdi-home",
      "rules": [_vm.rules.required],
      "items": this.streetSuggestions,
      "auto-select-first": ""
    },
    on: {
      "blur": function ($event) {
        return _vm.checkIfDuplicate();
      },
      "keyup": function ($event) {
        return _vm.updateStreetSuggestions();
      }
    },
    model: {
      value: _vm.address.address.street,
      callback: function ($$v) {
        _vm.$set(_vm.address.address, "street", $$v);
      },
      expression: "address.address.street"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "xl": "3",
      "lg": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "* Tel. 1",
      "append-icon": "mdi-phone",
      "rules": [_vm.rules.phoneNumber, _vm.rules.required]
    },
    model: {
      value: _vm.address.tel,
      callback: function ($$v) {
        _vm.$set(_vm.address, "tel", $$v);
      },
      expression: "address.tel"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "xl": "3",
      "lg": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-pencil",
      "label": "Anmerkung"
    },
    model: {
      value: _vm.address.telNote,
      callback: function ($$v) {
        _vm.$set(_vm.address, "telNote", $$v);
      },
      expression: "address.telNote"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "xl": "3",
      "lg": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Tel. Mobil",
      "append-icon": "mdi-phone",
      "rules": [_vm.rules.phoneNumber]
    },
    model: {
      value: _vm.address.mobile,
      callback: function ($$v) {
        _vm.$set(_vm.address, "mobile", $$v);
      },
      expression: "address.mobile"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "3",
      "xl": "3",
      "lg": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-pencil",
      "label": "Anmerkung"
    },
    model: {
      value: _vm.address.mobileNote,
      callback: function ($$v) {
        _vm.$set(_vm.address, "mobileNote", $$v);
      },
      expression: "address.mobileNote"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-email",
      "label": "E-Mail",
      "placeholder": "max.mustermann@gmail.com",
      "rules": [_vm.rules.emailFormat],
      "type": "email"
    },
    model: {
      value: _vm.address.email,
      callback: function ($$v) {
        _vm.$set(_vm.address, "email", $$v);
      },
      expression: "address.email"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Heim",
      "items": this.options.residences,
      "item-text": "displayName",
      "item-value": "key",
      "clearable": "",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.residence,
      callback: function ($$v) {
        _vm.$set(_vm.address, "residence", $$v);
      },
      expression: "address.residence"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Kundengruppe",
      "items": this.options.customerGroups,
      "item-text": "name",
      "item-value": "key",
      "clearable": "",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.customerGroup,
      callback: function ($$v) {
        _vm.$set(_vm.address, "customerGroup", $$v);
      },
      expression: "address.customerGroup"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.address.isAdultRepresentation,
      expression: "!address.isAdultRepresentation"
    }],
    attrs: {
      "outlined": "",
      "label": "Erwachsenenvertretung",
      "items": this.options.adultRepresentations,
      "item-text": "displayName",
      "item-value": "key",
      "clearable": "",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.adultRepresentation,
      callback: function ($$v) {
        _vm.$set(_vm.address, "adultRepresentation", $$v);
      },
      expression: "address.adultRepresentation"
    }
  })], 1)], 1)], 1), _c('WarningDialog', {
    ref: "warning",
    on: {
      "warningActionPerformed": _vm.warningActionPerformed
    }
  }), _c('DuplicateFoundDialog', {
    ref: "duplicate"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };