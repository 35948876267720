import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VForm, {
    on: {
      "input": function ($event) {
        _vm.updateValidity(_vm.formValidity), _vm.emitChange();
      }
    },
    model: {
      value: _vm.formValidity,
      callback: function ($$v) {
        _vm.formValidity = $$v;
      },
      expression: "formValidity"
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Kundennummer",
      "append-icon": "mdi-numeric"
    },
    model: {
      value: _vm.address.supplier.kdNr,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "kdNr", $$v);
      },
      expression: "address.supplier.kdNr"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "UID",
      "append-icon": "mdi-identifier"
    },
    on: {
      "change": function ($event) {
        return _vm.syncUID();
      }
    },
    model: {
      value: _vm.address.supplier.uid,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "uid", $$v);
      },
      expression: "address.supplier.uid"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "xl": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Lieferantengruppe",
      "items": this.supplierOptions.supplierGroups,
      "item-text": "description",
      "item-value": "id",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.supplier.supplierGroup,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "supplierGroup", $$v);
      },
      expression: "address.supplier.supplierGroup"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "md": "4",
      "xl": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Verband",
      "items": this.supplierOptions.associations,
      "item-text": "displayName",
      "item-value": "key",
      "prepend-inner-icon": "mdi-account-group",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.supplier.association,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "association", $$v);
      },
      expression: "address.supplier.association"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Branche",
      "items": this.supplierOptions.industrialSectors,
      "item-text": "description",
      "item-value": "id",
      "prepend-inner-icon": "mdi-account-hard-hat",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.supplier.industrialSector,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "industrialSector", $$v);
      },
      expression: "address.supplier.industrialSector"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Fibugruppe",
      "items": this.supplierOptions.fibuGroups,
      "item-text": "description",
      "item-value": "id",
      "prepend-inner-icon": "mdi-account-group",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.supplier.fibuGroup,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier, "fibuGroup", $$v);
      },
      expression: "address.supplier.fibuGroup"
    }
  })], 1)], 1), _c('h2', {
    staticClass: "overline font-weight-black mt-3"
  }, [_vm._v("Lieferung und Bestellung")]), _c(VDivider), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Versandart",
      "items": this.supplierOptions.shippingMethods,
      "item-text": "description",
      "item-value": "id",
      "prepend-inner-icon": "mdi-truck",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.shippingMethod,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "shippingMethod", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.shippingMethod"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Lieferbedingung",
      "items": this.supplierOptions.shippingConditions,
      "item-text": "description",
      "item-value": "id",
      "prepend-inner-icon": "mdi-truck-alert",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.shippingCondition,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "shippingCondition", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.shippingCondition"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "PA auf Anfrage"
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.priceOnDemand,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "priceOnDemand", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.priceOnDemand"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "PA auf Bestellung"
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.priceOnOrder,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "priceOnOrder", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.priceOnOrder"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Rechnungssperre"
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.invoiceBlocking,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "invoiceBlocking", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.invoiceBlocking"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "suffix": "Tage",
      "outlined": "",
      "rules": [_vm.rules.onlyPositive, _vm.rules.onlyNumbers],
      "label": "Sammelliefers. Int."
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.collectiveDeliveryNoteInterval,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "collectiveDeliveryNoteInterval", $$v);
      },
      expression: "\n          address.supplier.deliveryAndOrder.collectiveDeliveryNoteInterval\n        "
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "suffix": "Tage",
      "outlined": "",
      "rules": [_vm.rules.onlyPositive, _vm.rules.onlyNumbers],
      "label": "Sammelr. Int."
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.collectiveInvoiceInterval,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "collectiveInvoiceInterval", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.collectiveInvoiceInterval"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Filialdirektbel."
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.directBranchShipping,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "directBranchShipping", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.directBranchShipping"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Kundendirektbel."
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.directCustomerDelivery,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "directCustomerDelivery", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.directCustomerDelivery"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "2",
      "md": "2",
      "xl": "2"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Verband Mwst."
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.associationVat,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "associationVat", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.associationVat"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Kreditorennummer",
      "append-icon": "mdi-numeric"
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.creditorNumber,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "creditorNumber", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.creditorNumber"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Anbruchpackungen",
      "items": this.supplierOptions.openedPacks,
      "prepend-inner-icon": "mdi-package-variant",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.openedPacks,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "openedPacks", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.openedPacks"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Dezimalstellen",
      "rules": [_vm.rules.onlyPositive, _vm.rules.onlyNumbers],
      "append-icon": "mdi-decimal-comma"
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.decimalPlaces,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "decimalPlaces", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.decimalPlaces"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "md": "3",
      "xl": "3"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Mindestbestellwert",
      "rules": [_vm.rules.onlyPositive, _vm.rules.onlyNumbers],
      "append-icon": "mdi-currency-eur"
    },
    model: {
      value: _vm.address.supplier.deliveryAndOrder.minimumOrderValue,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.deliveryAndOrder, "minimumOrderValue", $$v);
      },
      expression: "address.supplier.deliveryAndOrder.minimumOrderValue"
    }
  })], 1)], 1), _c('h2', {
    staticClass: "overline font-weight-black mt-3"
  }, [_vm._v("Bezahlung")]), _c(VDivider), _c(VRow, {
    staticClass: "mt-3"
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-cash",
      "items": this.supplierOptions.paymentConditions,
      "item-text": "description",
      "item-value": "id",
      "label": "Zahlungsbedingung",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.supplier.payment.paymentConditions,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "paymentConditions", $$v);
      },
      expression: "address.supplier.payment.paymentConditions"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": "mdi-cash",
      "items": this.supplierOptions.paymentMethods,
      "item-text": "description",
      "item-value": "id",
      "label": "Zahlungsart",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.supplier.payment.paymentTypes,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "paymentTypes", $$v);
      },
      expression: "address.supplier.payment.paymentTypes"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-credit-card",
      "label": "IBAN",
      "rules": [_vm.rules.validIban]
    },
    model: {
      value: _vm.address.supplier.payment.iban,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "iban", $$v);
      },
      expression: "address.supplier.payment.iban"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "6",
      "md": "6",
      "xl": "6"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "append-icon": "mdi-numeric",
      "label": "BIC"
    },
    model: {
      value: _vm.address.supplier.payment.bic,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "bic", $$v);
      },
      expression: "address.supplier.payment.bic"
    }
  })], 1)], 1), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VCheckbox, {
    attrs: {
      "label": "Sperre Zahlungsvorschlag",
      "prepend-icon": "mdi-lock"
    },
    model: {
      value: _vm.address.supplier.payment.lockPaymentProposal,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "lockPaymentProposal", $$v);
      },
      expression: "address.supplier.payment.lockPaymentProposal"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "3",
      "xl": "3",
      "md": "3"
    }
  }, [_c(VMenu, {
    ref: "menu",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.date,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    on: {
      "update:returnValue": function ($event) {
        _vm.date = $event;
      },
      "update:return-value": function ($event) {
        _vm.date = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        attrs
      }) {
        return [_c(VTextField, _vm._b({
          attrs: {
            "outlined": "",
            "label": "Einzugsermächtigungsdatum",
            "append-icon": "mdi-calendar",
            "rules": [_vm.rules.legalDate]
          },
          on: {
            "click:append": function ($event) {
              _vm.menu = true;
            },
            "blur": function ($event) {
              _vm.date = _vm.parseDate(_vm.dateFormatted);
            }
          },
          model: {
            value: _vm.dateFormatted,
            callback: function ($$v) {
              _vm.dateFormatted = $$v;
            },
            expression: "dateFormatted"
          }
        }, 'v-text-field', attrs, false))];
      }
    }]),
    model: {
      value: _vm.menu,
      callback: function ($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c(VDatePicker, {
    attrs: {
      "color": "primary",
      "no-title": "",
      "scrollable": ""
    },
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.menu = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.menu.save(_vm.date);
      }
    }
  }, [_vm._v(" OK ")])], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "xl": "4",
      "md": "4"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "outlined": "",
      "label": "Rabattart",
      "items": this.supplierOptions.discountTypes,
      "item-text": "description",
      "item-value": "id",
      "prepend-inner-icon": "mdi-percent",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.supplier.payment.discountType,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "discountType", $$v);
      },
      expression: "address.supplier.payment.discountType"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "2",
      "lg": "2",
      "xl": "2",
      "md": "2"
    }
  }, [_c(VTextField, {
    attrs: {
      "outlined": "",
      "label": "Rabattausmaß",
      "suffix": "%",
      "rules": [_vm.rules.onlyNumbers]
    },
    model: {
      value: _vm.address.supplier.payment.discount,
      callback: function ($$v) {
        _vm.$set(_vm.address.supplier.payment, "discount", $$v);
      },
      expression: "address.supplier.payment.discount"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };