import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
import axios from 'axios';
export default {
  components: {},
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newPerson,
      patientOptions: state => state.crmAddresses.patientOptions,
      validPatient: state => state.crmAddresses.validPatient,
      options: state => state.crmAddresses.addressOptions
    }),
    hvnr() {
      return this.address.patient.familyDoctor !== null ? this.address.patient.familyDoctor.hvnr : '';
    }
  },
  data: () => ({
    rules: {
      onlyPositive: value => value >= 0 || value == null || value == '' || 'Wert kann nicht negativ sein!',
      onlyNumbers: v => !v || /^[0-9]*$/.test(v) || 'Nur Zahlen erlaubt!',
      emailFormat: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!'
    },
    formValidity: false,
    errorMessages: []
  }),
  methods: {
    emitChange() {
      this.$emit('change');
    },
    updateValidity(newValidity) {
      this.$store.commit('updateValidPatient', newValidity);
    },
    checkIfRelative() {
      let insuredGroup = this.patientOptions.insuredGroups.filter(elem => elem.id == this.address.patient.insuredGroup)[0];
      if (insuredGroup != undefined && insuredGroup.description.includes('Angehörige')) {
        this.address.isRelative = true;
      } else {
        this.address.isRelative = false;
      }
    },
    async checkSVNR() {
      let svnr = this.address.patient.svnr + '' + this.address.patient.svnrDat;
      await axios.post('v1/crm/addresses/new/checksv', null, {
        params: {
          svnr: svnr
        }
      }).then(res => {
        if (res.data) {
          this.errorMessages = [];
          return true;
        } else {
          this.errorMessages.push('SVNR ungültig!');
          return false;
        }
      }).catch(() => {
        this.errorMessages.push('SVNR ungültig!');
        return false;
      });
    }
  }
};