import dateTimeService from '@/services/cal/dateTimeService.js';
import addressService from '@/services/crm/addressService.js';
import { mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      address: state => state.crmAddresses.newNonPerson,
      supplierOptions: state => state.crmAddresses.supplierOptions,
      options: state => state.crmAddresses.addressOptions
    })
  },
  data: () => ({
    rules: {
      onlyPositive: value => value >= 0 || value == null || value == '' || 'Wert kann nicht negativ sein!',
      emailFormat: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail muss gültige Form haben!',
      required: value => !!value || 'Feld ist verpflichtend!',
      onlyNumbers: value => value == '' || value == null || /^-?\d{1,}(?:,\d+)?$/.test(value) || 'Nur Zahlen sind erlaubt!',
      legalDate: value => {
        const [day, month, year] = value.split('.');
        return dateTimeService.isValidDate(day, month, year) || 'Kein gültiges Datum!';
      },
      validIban: value => !value || addressService.isIbanValid(value) || 'Kein gültiger IBAN!'
    },
    date: null,
    dateFormatted: null,
    menu: false,
    formValidity: true
  }),
  methods: {
    emitChange() {
      this.$emit('change');
    },
    parseDate(date) {
      if (!date) return null;
      if (date.includes('.')) {
        return this.parseDottedDate(date);
      } else {
        return this.parseShortDate(date);
      }
    },
    parseDottedDate(date) {
      //e.g.: 13.01.1990
      const [day, month, year] = date.split('.');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    parseShortDate(date) {
      //e.g.: 13011990
      const day = date.slice(0, 2);
      const month = date.slice(2, 4);
      const year = date.slice(4);
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    updateValidity(newValidity) {
      this.$store.commit('updateValidSupplier', newValidity);
    },
    syncUID() {
      this.address.company.uid = this.address.supplier.uid;
    }
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
      this.address.supplier.payment.directDepitAuthorisationDate = this.date;
    }
  }
};