import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VExpansionPanels, {
    staticClass: "mt-n3",
    attrs: {
      "accordion": "",
      "multiple": ""
    },
    model: {
      value: _vm.panelsOpenedByDefault,
      callback: function ($$v) {
        _vm.panelsOpenedByDefault = $$v;
      },
      expression: "panelsOpenedByDefault"
    }
  }, [_c('div', {
    ref: "allgemein"
  }), _c('LBAExpansionPanel', {
    attrs: {
      "text-color": 'primary',
      "bgColor": 'white',
      "titleType": 'headline',
      "sideBar": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Adressinformation")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('AddressinformationForm', {
          on: {
            "change": function ($event) {
              return _vm.emitChange();
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    ref: "patient"
  }), _c('LBAExpansionPanel', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.isPatient,
      expression: "address.isPatient"
    }],
    attrs: {
      "text-color": 'primary',
      "bgColor": 'white',
      "titleType": 'headline',
      "sideBar": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.addressTypes.find(type => type.fldName === 'kd_patient').fldDisplayName))];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('PatientinformationForm', {
          on: {
            "change": function ($event) {
              return _vm.emitChange();
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('LBAExpansionPanel', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.isRelative && _vm.address.isPatient,
      expression: "address.isRelative && address.isPatient"
    }],
    attrs: {
      "text-color": 'primary',
      "bgColor": 'white',
      "titleType": 'headline',
      "sideBar": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Hauptversichertendaten")];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('MainInsuranceDataForm', {
          on: {
            "change": function ($event) {
              return _vm.emitChange();
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    ref: "arzt"
  }), _c('LBAExpansionPanel', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.isDoctor,
      expression: "address.isDoctor"
    }],
    attrs: {
      "text-color": 'primary',
      "bgColor": 'white',
      "titleType": 'headline',
      "sideBar": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.addressTypes.find(type => type.fldName === 'kd_arzt').fldDisplayName))];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('DoctorinformationForm', {
          on: {
            "change": function ($event) {
              return _vm.emitChange();
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    ref: "verkaeufer"
  }), _c('LBAExpansionPanel', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.address.isEmployee,
      expression: "address.isEmployee"
    }],
    attrs: {
      "text-color": 'primary',
      "bgColor": 'white',
      "titleType": 'headline',
      "sideBar": false
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.addressTypes.find(type => type.fldName === 'kd_verkaeufer').fldDisplayName))];
      },
      proxy: true
    }, {
      key: "content",
      fn: function () {
        return [_c('EmployeeinformationForm', {
          on: {
            "change": function ($event) {
              return _vm.emitChange();
            }
          }
        })];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };