import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VForm, {
    on: {
      "input": function ($event) {
        _vm.updateValidity(_vm.formValidity), _vm.emitChange();
      }
    },
    model: {
      value: _vm.formValidity,
      callback: function ($$v) {
        _vm.formValidity = $$v;
      },
      expression: "formValidity"
    }
  }, [_c(VRow, [_c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "4",
      "xl": "4",
      "md": "4"
    }
  }, [_c(VTextField, {
    attrs: {
      "label": "HV-Nr.",
      "outlined": "",
      "append-icon": "mdi-numeric",
      "rules": [_vm.rules.required, _vm.rules.sixDigitNumber]
    },
    model: {
      value: _vm.address.doctor.hvnr,
      callback: function ($$v) {
        _vm.$set(_vm.address.doctor, "hvnr", $$v);
      },
      expression: "address.doctor.hvnr"
    }
  })], 1), _c(VCol, {
    attrs: {
      "cols": "12",
      "lg": "8",
      "xl": "8",
      "md": "8"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.doctorOptions.subjects,
      "item-text": "description",
      "item-value": item => item,
      "label": "Fachrichtung",
      "outlined": "",
      "prepend-inner-icon": "mdi-stethoscope",
      "auto-select-first": ""
    },
    model: {
      value: _vm.address.doctor.subject,
      callback: function ($$v) {
        _vm.$set(_vm.address.doctor, "subject", $$v);
      },
      expression: "address.doctor.subject"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };