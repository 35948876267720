import axios from 'axios'

export default {
  getCityNameByPostcodeAndCountry(postcode, country) {
    return axios.post('v1/util/autofill/city', {
      plz: postcode,
      country: country
    })
  },
  getStreetSuggestionByPostcodeAndSearchString(postcode, searchString) {
    return axios.post('v1/util/autofill/street', {
      plz: postcode,
      query: searchString
    })
  }
}
